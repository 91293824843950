<template>
<div></div>
</template>

<script>
import { getCurrentInstance } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { CrossStorageHub } from 'cross-storage';

const componentState = () => {
  const { proxy } = getCurrentInstance()
  const route = useRoute()
  const router = useRouter()
  if(proxy.$Util.isEmpty(route.query.client)) { 
    alert('잘못된 접근입니다.')
    router.push({ name: 'main-index' })
  }
  const origin = new RegExp(`${route.query.client}`)
  CrossStorageHub.init([
    { origin: /:\/\/(www\.)?wizzney.com$/, allow: ['get', 'set', 'del', 'getKeys', 'clear']},
    { origin: /\.wizzney.com$/, allow: ['get', 'set', 'del', 'getKeys', 'clear']},
    { origin, allow: ['get', 'set', 'del', 'getKeys', 'clear']}
  ])
  
  return {}

}

export default {
  name: 'hub-index',
  setup() {
    return { ...componentState() }
  }
}
</script>